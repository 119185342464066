import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/jisr-home-frontend-cms/jisr-home-frontend-cms/src/strapi/DraftBanner/DraftBanner.tsx");
