import { cn } from '@/ds/utils/cn'
import { SVGProps } from 'react'

export type IconProps = Omit<SVGProps<SVGSVGElement>, 'src'> & {
  width?: number | string
  height?: number | string
  class1?: string
  class2?: string
  className?: string
}

const Loader = ({
  width = 14,
  height = 14,
  class1,
  class2,
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      className={cn('h-6 w-6 animate-spin text-primary', className)}
      viewBox="0 0 100 100"
    >
      <circle
        fill="none"
        strokeWidth="10"
        className={`stroke-current opacity-40 ${class1}`}
        cx="50"
        cy="50"
        r="40"
      />
      <circle
        fill="none"
        strokeWidth="10"
        className={`stroke-current ${class2}`}
        strokeDasharray="250"
        strokeDashoffset="210"
        cx="50"
        cy="50"
        r="40"
      />
    </svg>
  )
}

export default Loader
