'use client'

import Loader from '@/ds/atoms/Loader'
import { cn } from '@/ds/utils/cn'
import { useRouter } from 'next/navigation'
import { useState } from 'react'

function DraftBanner() {
  const [disabled, setDisabled] = useState(false)
  const router = useRouter()

  return (
    <div
      className={cn(
        'flex justify-center items-center p-4 gap-6 bg-primary-lightest',
        {
          'cursor-not-allowed opacity-50': disabled,
        }
      )}
    >
      <span>You are using the draft mode</span>
      <button
        onClick={e => {
          e.preventDefault()
          setDisabled(true)
          fetch('/api/exit-preview').then(() => {
            router.refresh()
          })
        }}
        disabled={disabled}
        className={cn(
          'bg-primary min-w-[150px] text-white outline py-2 px-4 rounded-md'
        )}
      >
        {disabled ? (
          <Loader className="text-white mx-auto" />
        ) : (
          'Exit Draft Mode'
        )}
      </button>
    </div>
  )
}

export default DraftBanner
